import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Header from '../components/header'
import Columns from '../components/columns'
import Sidebar from '../components/sidebar'
import Quote from '../components/quote'
import AboutNav from '../components/about-nav'

const image = require('../images/dt_cropped.png')

const AboutPage = () => (
  <Layout>
    <Helmet
      title={'About Adage Financial Consultants'}
      meta={[
        {
          name: 'description',
          content:
            // tslint:disable-next-line:max-line-length
            'Choosing a financial partner is about far more than just finances. Here at Adage Financial, we take pride in the options and services we offer our clients.',
        },
        {
          name: 'keywords',
          content:
            // tslint:disable-next-line:max-line-length
            'Adage Financial Consultants, David Templeton, Little Rock Arkansas, Financial Advisor, Financial Planner, 401k, Safe Money, Annuity',
        },
      ]}
    />
    <Header headline={'About AFC'} image={image} caption />

    <Columns background>
      <Sidebar>
        <AboutNav />
      </Sidebar>
      <div className="marketing__column">
        <Quote
          text={
            // tslint:disable-next-line:max-line-length
            'We help people create income streams that allow them to retire and most importantly, stay retired.'
          }
        />
        <p>
          Choosing a financial partner is about far more than just finances.
          Here at Adage Financial, we take pride in the options and services we
          offer our clients.
        </p>
        <p>
          Our mission is to provide sound recommendations designed for your
          greatest benefit. We strive to eliminate the guesswork often
          associated with retirement planning by providing clear information and
          recommendations. We assist in the design and then implement a plan for
          your retirement. We then remain by your side, monitoring how the plan
          is working, making any necessary adjustments as life changes along the
          way.
        </p>
        <p>
          Retirement represents a fundamental change in your life, yet most
          retirees don’t match that with a fundamental change in their
          portfolios. Our primary focus is to assist those planning for,
          entering into or already in retirement to make that transition
          smoothly.
        </p>
      </div>
    </Columns>
  </Layout>
)

export default AboutPage
